var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"promotion-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","disabled":_vm.hasNotPermission('Permissions.CounterPartyCreate')},on:{"click":function($event){return _vm.createDialogShow()}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Добавить")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"promotion-list-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Поиск..."},model:{value:(_vm.filters.searchQuery),callback:function ($$v) {_vm.$set(_vm.filters, "searchQuery", $$v)},expression:"filters.searchQuery"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.counterParties,"server-items-length":_vm.totalCount,"loading":_vm.loading,"page":_vm.filters.pageNumber,"items-per-page":_vm.filters.pageSize,"sort-by":_vm.filters.sortBy,"sort-desc":_vm.filters.sortDesc},on:{"update:page":function($event){return _vm.$set(_vm.filters, "pageNumber", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.filters, "pageSize", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filters, "pageSize", $event)},"update:sortBy":function($event){return _vm.$set(_vm.filters, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filters, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filters, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filters, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.rk7TableCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rk7TableCode)+" ")]}},{key:"item.rk7StationCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rk7StationCode)+" ")]}},{key:"item.externalSystemCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.externalSystemCode)+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comment)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":_vm.hasNotPermission('Permissions.CounterPartyUpdate')},on:{"click":function($event){return _vm.updateDialogShow(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])])],1)]}}],null,true)}),_c('counter-party-form',{attrs:{"label":'Редактирование информации о контрагенте',"cancellation":_vm.updateDialogCancel,"show":_vm.updateDialog,"save":_vm.updateCounterParty}}),_c('counter-party-form',{attrs:{"label":'Создание информации о контрагенте',"cancellation":_vm.createDialogCancel,"show":_vm.createDialog,"save":_vm.addCounterParty}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }