<template>
  <v-card id="promotion-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          :disabled="hasNotPermission('Permissions.CounterPartyCreate')"
          @click="createDialogShow()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="counterParties"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <template #[`item.type`]="{item}">
        {{ item.type }}
      </template>

      <template #[`item.rk7TableCode`]="{item}">
        {{ item.rk7TableCode }}
      </template>

      <template #[`item.rk7StationCode`]="{item}">
        {{ item.rk7StationCode }}
      </template>

      <template #[`item.externalSystemCode`]="{item}">
        {{ item.externalSystemCode }}
      </template>

      <template #[`item.comment`]="{item}">
        {{ item.comment }}
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <!-- edit -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :disabled="hasNotPermission('Permissions.CounterPartyUpdate')"
                v-on="on"
                @click="updateDialogShow(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <counter-party-form :label="'Редактирование информации о контрагенте'" :cancellation="updateDialogCancel" :show="updateDialog"
                                  :save="updateCounterParty"></counter-party-form>
    <counter-party-form :label="'Создание информации о контрагенте'" :cancellation="createDialogCancel" :show="createDialog"
                                  :save="addCounterParty"></counter-party-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import CounterPartyForm from './counter-party-form'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { hasPermission } from '@/helpers/index'

export default {
  components: { CounterPartyForm },
  name: 'CounterPartyList',
  data: () => ({
    createDialog: false,
    updateDialog: false,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
    },
    deleteLabel: '',
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('counterParty', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('counterParty', {
      counterParties: 'list',
      totalCount: 'totalCount',
      loading: 'loading'
    }),
    headers() {
      return [
        { text: 'Наименование', value: 'type' },
        { text: 'Код стола', value: 'rk7TableCode' },
        { text: 'Код станции', value: 'rk7StationCode' },
        { text: 'Сторонняя система', value: 'externalSystemCode' },
        { text: 'Комментарий', value: 'comment' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Контрагенты'
    this.fetchCounterParties()
  },
  mounted() {
  },
  methods: {
    ...mapActions('counterParty', {
      fetchCounterParties: 'fetchList'
    }),
    ...mapActions('counterParty/form', ['create', 'resetForm', 'fetchById', 'update']),
    hasNotPermission(val){
      return !hasPermission(val);
    },
    async addCounterParty() {
      await this.create()
      this.createDialog = false
    },
    async updateCounterParty() {
      await this.update()
      this.updateDialog = false
    },
    createDialogShow() {
      this.createDialog = true
    },
    createDialogCancel(){
      this.createDialog = false
    },
    updateDialogCancel() {
      this.updateDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
